<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('tasks.title') }}</h1>
    </template>
    <template #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    </template>

    <div v-if="tasksLoading" v-loading="true" class="loader" />
    <template v-else-if="uiTasks.length">
      <div class="task-list">
        <div class="task-list-header">
          <div class="card-column fw-bold">{{ $t('tasks.taskList.headers.supplier') }}</div>
          <div class="card-column fw-bold">{{ $t('tasks.taskList.headers.action') }}</div>
          <div class="card-column fw-bold">{{ $t('tasks.taskList.headers.description') }}</div>
          <div class="card-column last fw-bold">{{ $t('tasks.taskList.headers.createDate') }}</div>
        </div>
        <div v-for="uiTask in uiTasks" :key="uiTask.id" class="task-card-container">
          <TaskCard
            :supplier="uiTask.supplier"
            :action="uiTask.action"
            :info="uiTask.info"
            :description="uiTask.description"
            :description-subtitle="uiTask.descriptionSubtitle"
            :created="uiTask.created"
            :completed="uiTask.completed"
            :user="uiTask.user"
            @click="handleEventMapModalOpen(uiTask.id)"
          />
        </div>
        <el-pagination
          small
          background
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :total="totalTasks"
          layout="pager, prev, next, jumper"
          class="mb-2"
          :class="$direction === 'rtl' ? 'text-left' : 'text-right'"
        />
      </div>
    </template>
    <template v-else>
      <!-- TODO - add image when list is empty (WIP) -->
    </template>
    <EventMapModal v-if="selectedEventMapItem" :activity="selectedEventMapItem" @close="selectedEventMapItem = null" />
    <ProductMergeTaskModal
      v-if="selectedProductTask"
      :product-id="selectedProductTask.referredObject.product.id"
      :task-id="selectedProductTask.id"
      :is-task-completed="!!selectedProductTask.completedAt"
      @close="selectedProductTask = null"
    />
  </PageLayout>
</template>

<script>
import { computed, ref, watch, getCurrentInstance } from 'vue';

import i18n from '@/imports/startup/client/i18n';
import { options } from '@/locale/dateConfig';
import { PageLayout, Tabs } from '@/modules/core/components';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useTenancy } from '@/modules/auth';

import { useTasks } from './compositions/useTasks';
import { TaskCard } from './components';
import ProductMergeTaskModal from './ProductMergeTaskModal';

const TASK_REFERRED_OBJECT_TYPE = {
  ORDER: 'order',
  PRODUCT: 'product',
};

const TASKS_DEFAULT_LIMIT = 20;

export const formatDate = (ms) => {
  return ms ? new Date(ms).toLocaleDateString(i18n.locale, options.long) : null;
};

const productTasksMapper = (task) => {
  const {
    id,
    referredObject: { product },
    createdAt,
    completedAt,
    completedBy,
  } = task;

  const isOpenTask = !completedAt;
  const actionText = i18n.t('tasks.task.action.product');

  const productText = `${product.name} ${product.sku ? `(${i18n.t('commons.sku')} ${product.sku})` : ''}`;
  const descriptionText = isOpenTask ? productText : getCompletedByText({ completedAt, completedBy });

  return {
    id,
    supplier: product.business,
    action: actionText,
    description: descriptionText,
    descriptionText: productText,
    descriptionSubtitle: productText,
    created: createdAt,
    completed: completedAt,
    user: completedBy?.profile,
  };
};

const getCompletedByText = ({ completedAt, completedBy }) => {
  return completedBy
    ? i18n.t('tasks.task.description.handled.byUser', {
        user: `${completedBy.profile.firstName} ${completedBy.profile.lastName}`,
        date: formatDate(completedAt),
      })
    : i18n.t('tasks.task.description.handled.byNone', {
        date: formatDate(completedAt),
      });
};

const handledOrderTasksMapper = (task) => {
  const {
    referredObject: { order },
    completedBy,
    id,
    createdAt,
    completedAt,
  } = task;
  const actionText = i18n.t('tasks.task.action.order');
  const infoTexts = [];
  if (order.source?.document) {
    const { type: documentType, documentNumber } = order.source?.document;
    const docTypeText = i18n.t(`document.exports.schema.type.shortName.${documentType}`);
    infoTexts.push(`${docTypeText} ${documentNumber}`);
  }

  infoTexts.push(formatDate(order.date));

  const descriptionText = getCompletedByText({ completedAt, completedBy });

  return {
    id,
    supplier: order?.supplier,
    action: actionText,
    info: infoTexts.join(' \u2022 '),
    description: descriptionText,
    created: createdAt,
    completed: completedAt,
    user: completedBy?.profile,
  };
};

export default {
  components: {
    PageLayout,
    ProductMergeTaskModal,
    Tabs,
    TaskCard,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { formatCentsToCurrency } = useCurrency();

    const activeTab = ref(0);
    const selectedEventMapItem = ref(null);
    const selectedProductTask = ref(null);

    const currentPage = ref(1);
    watch(activeTab, () => (currentPage.value = 1), { immediate: true });
    const pagination = computed(() => ({
      limit: TASKS_DEFAULT_LIMIT,
      offset: (currentPage.value - 1) * TASKS_DEFAULT_LIMIT,
    }));

    const {
      tasks: openTasks,
      totalCount: openTotalCount,
      loading: openLoading,
    } = useTasks(() => ({
      businessId: currentTenant.value.id,
      completed: false,
      domains: Object.values(TASK_REFERRED_OBJECT_TYPE),
      ...pagination.value,
    }));
    const {
      tasks: completedTasks,
      totalCount: completedTotalCount,
      loading: completedLoading,
    } = useTasks(() => ({
      businessId: currentTenant.value.id,
      completed: true,
      domains: Object.values(TASK_REFERRED_OBJECT_TYPE),
      ...pagination.value,
    }));

    const totalTasks = computed(() => (activeTab.value === 0 ? openTotalCount.value : completedTotalCount.value));
    const shownTasks = computed(() => (activeTab.value === 0 ? openTasks.value : completedTasks.value));

    const openedOrderTasksMapper = (task) => {
      const {
        referredObject: { order },
        id,
        createdAt,
      } = task;
      const actionText = i18n.t('tasks.task.action.order');
      const infoTexts = [];
      if (order?.source?.document) {
        const { type: documentType, documentNumber } = order.source?.document;
        const docTypeText = i18n.t(`document.exports.schema.type.shortName.${documentType}`);
        infoTexts.push(`${docTypeText} ${documentNumber}`);
      }

      infoTexts.push(formatDate(order.date));

      const totalDifferenceAmount = order.differences.reduce((acc, value) => {
        return acc + value.amount;
      }, 0);

      const descriptionText = i18n.t('tasks.task.description.opened.order', {
        count: order.differences.length,
        amount: formatCentsToCurrency(totalDifferenceAmount),
      });

      return {
        id,
        supplier: order?.supplier,
        action: actionText,
        info: infoTexts.join(' \u2022 '),
        description: descriptionText,
        created: createdAt,
      };
    };

    return {
      tasksLoading: openLoading && completedLoading,
      activeTab,
      selectedEventMapItem,
      selectedProductTask,
      currentPage,
      totalTasks,
      pageSize: TASKS_DEFAULT_LIMIT,
      tabs: computed(() => [
        {
          text: root.$i18n.t('tasks.tabs.opened'),
          badgeValue: openTotalCount.value,
        },
        {
          text: root.$i18n.t('tasks.tabs.handled'),
          badgeValue: completedTotalCount.value,
        },
      ]),
      uiTasks: computed(() => {
        return shownTasks.value.map((task) => {
          switch (task.referredObject.type) {
            case TASK_REFERRED_OBJECT_TYPE.ORDER:
              return activeTab.value === 0 ? openedOrderTasksMapper(task) : handledOrderTasksMapper(task);
            case TASK_REFERRED_OBJECT_TYPE.PRODUCT:
              return productTasksMapper(task);
          }
        });
      }),
      handleEventMapModalOpen(taskId) {
        const task = shownTasks.value.find((task) => task.id === taskId);

        switch (task.referredObject.type) {
          case TASK_REFERRED_OBJECT_TYPE.ORDER:
            selectedEventMapItem.value = {
              id: task.referredObject.order.id,
              type: task.referredObject.type,
            };
            break;
          case TASK_REFERRED_OBJECT_TYPE.PRODUCT:
            selectedProductTask.value = task;
            break;
          default:
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import './style';

.task-page {
  color: $typography-primary;
}

.task-header {
  padding: 40px 28px;
}

::v-deep .task-tabs {
  .tabs-container__wrapper {
    padding: 0px 24px;
  }
  margin-bottom: 14px;
}

.task-list-header {
  @extend .task-card;

  &:hover {
    cursor: default;
  }

  background: transparent;
  border: none;
  border-radius: 0;
}

.task-list {
  padding: 0px 20px;
  max-width: 1900px;

  .task-card-container {
    margin-bottom: 10px;
  }
}

.loader {
  margin-top: 25vh;
}
</style>
