<template>
  <div>
    <PageLayout class="container">
      <template v-if="!isPlayMode && !selectedRowTaskId" #header>
        <div class="container-xxl title">
          <h1>
            {{ $t('tasks.tasksPage.title') }}
          </h1>
        </div>
      </template>

      <div v-if="!isPlayMode && !selectedRowTaskId" class="container-xxl">
        <div class="actions">
          <div class="filters">
            <CheckableDropdown
              :initial-selected-ids="initialTasksIds"
              :options="tasksType"
              :label="$t('document.documentsOperationOverview.openTasksTable.filters.task')"
              @change="handleTasksTypeSelect"
            />

            <MultiSelect
              :title="`${$t('tasks.tasksPage.filters.business')}${
                selectedBusinesses.length ? ` (${selectedBusinesses.length})` : ''
              }`"
              :options="businesses"
              :selected="selectedBusinesses"
              :class="{ 'filter-is-dirty': selectedBusinesses?.length }"
              :placeholder="`${$t('tasks.tasksPage.filters.business')}${
                selectedBusinesses.length ? ` (${selectedBusinesses.length})` : ''
              }`"
              @on-select="handleBusinessSelect"
              @on-search-change="handleBusinessSearchChange"
              @load-more="handleLoadMoreBusiness"
            />

            <DropdownTableFilter
              trigger="click"
              :clearable="false"
              :filter-name="$t('tasks.tasksPage.filters.reviewRequired.title')"
              :filter-value="
                selectedReviewOption !== REVIEW_REQUIRED_OPTION.ALL
                  ? $t(`tasks.tasksPage.filters.reviewRequired.${selectedReviewOption}`)
                  : ''
              "
              :class="{ 'filter-is-dirty': selectedReviewOption !== REVIEW_REQUIRED_OPTION.ALL }"
              :placement="$direction === 'rtl' ? 'bottom-end' : 'bottom-start'"
              @on-choose-item="handleReviewSelect"
            >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="option in reviewOptions"
                  :key="option.id"
                  :command="option.id"
                  :class="{ 'dropdown-selected-item': selectedReviewOption === option.id }"
                >
                  {{ option.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </DropdownTableFilter>
          </div>

          <el-tooltip
            :content="
              $t(
                tasksTotalCount < 1 || !activeTasks?.length
                  ? 'tasks.tasksPage.playMode.startBtn.noTasksTooltip'
                  : typeof isReviewRequired === 'undefined'
                  ? 'tasks.tasksPage.playMode.startBtn.reviewRequiredTooltip'
                  : 'tasks.tasksPage.playMode.startBtn.tooltip'
              )
            "
            placement="top"
            effect="dark"
          >
            <Button
              :disabled="tasksTotalCount < 1 || typeof isReviewRequired === 'undefined' || !activeTasks?.length"
              @click="showPlayMode"
            >
              {{ $t('tasks.tasksPage.playMode.startBtn.label') }}
              <PlayIcon :rotate="true" :size="14" />
            </Button>
          </el-tooltip>
        </div>
        <div class="tasks-table">
          <TasksTable
            :tasks="tasks"
            :page-limit="PAGE_LIMIT"
            :current-page="currentPage"
            :loading="tasksLoading || fakeLoading"
            @on-row-click="handleRowClick"
          />
        </div>

        <div class="d-flex justify-content-end my-3">
          <el-pagination
            small
            layout="prev, pager, next, jumper"
            background
            :current-page.sync="currentPage"
            :page-size="PAGE_LIMIT"
            :page-count="Math.ceil(tasksTotalCount / PAGE_LIMIT)"
            :total="tasksTotalCount"
          />
        </div>
      </div>

      <div v-else>
        <PlayMode
          :should-play-single-task="selectedRowTaskId && !isPlayMode"
          :task-id="selectedRowTaskId"
          :business-ids="tasksFilters.businessIds"
          :domains="tasksFilters.domains"
          :types="tasksFilters.types"
          :review-required="tasksFilters.reviewRequired"
          :tasks-total-count="tasksTotalCount"
          @hide-play-mode="hidePlayMode"
          @on-task-complete="onTaskComplete"
          @on-task-skip="onTaskSkip"
        />
      </div>
    </PageLayout>
  </div>
</template>

<script type="text/javascript">
import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch } from 'vue';
import { uniqBy, debounce } from 'lodash';
import { useRoute, useRouter } from 'vue-router/composables';
import { PageLayout, Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useAllTasks } from './compositions';
import { useAccumulativeBusinesses } from '../contactSet/composition/businesses';
import TasksTable from './components/TasksTable.vue';
import { MultiSelect } from './components';
import { useBusinessByNames } from '../business/compositions/business';
import { DropdownTableFilter } from '@/modules/core';
import { CheckableDropdown } from './task/components/table-filters';

import { PlayIcon } from '@/assets/icons';
import PlayMode from './components/PlayMode.vue';

const TASK_TYPE = {
  DEFINE_PRODUCT: 'define_product',
  ALLOCATION_BILLING: 'allocation_billing',
  DECLARE_DELIVERY: 'declare_delivery',
  MAP_PRODUCTS: 'map_product',
  BALANCEALIGNMENT_RECONCILIATION: 'balanceAlignment_reconciliation',
  HANDLERECONCILIATION_RECONCILIATION: 'handleReconciliation_reconciliation',
  UNCERTAINBILLING_BILLING: 'uncertainBilling_billing',
};

const REVIEW_REQUIRED_OPTION = {
  ALL: 'all',
  REVIEW_REQUIRED: 'reviewRequired',
  REVIEW_NOT_REQUIRED: 'reviewNotRequired',
};

const PAGE_LIMIT = 15;
const BUSINESSES_LIMIT = 100;
const FIRST_TIME_FAKE_LOADING_TIME_MS = 500;
const TASKS_REFETCH_INTERVAL_MS = 60000;

export default {
  components: {
    PageLayout,
    TasksTable,
    MultiSelect,
    DropdownTableFilter,
    Button,
    PlayIcon,
    PlayMode,
    CheckableDropdown,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const route = useRoute();
    const router = useRouter();
    const currentPage = ref(1);
    const searchFilter = ref({ value: '', debounced: '' });
    const after = ref(0);
    const selectedBusinesses = ref([]);
    const selectedReviewOption = ref(route.query.reviewOption || REVIEW_REQUIRED_OPTION.ALL);
    const intervalId = ref(null);

    const tasksFromQueryParams =
      route.query.tasks?.split(',').reduce((acc, task) => {
        return [...acc, { id: task }];
      }, []) || [];

    const selectedTasksTypes = ref(tasksFromQueryParams);

    const businessesWithSelected = ref([]);
    const businessesParam = ref(route.query.businessIds?.split(','));
    const isFirstFetchBusinesses = ref(true);
    const isFirstFetchReviewOption = ref(true);
    const isPlayMode = ref(false);
    const fakeLoading = ref(true);
    const timeoutId = ref(null);
    const selectedRowTaskId = ref(null);

    onMounted(() => {
      timeoutId.value = setTimeout(() => {
        fakeLoading.value = false;
      }, FIRST_TIME_FAKE_LOADING_TIME_MS);
    });

    onUnmounted(() => {
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
    });

    const reviewOptions = ref([
      {
        id: REVIEW_REQUIRED_OPTION.ALL,
        name: root.$i18n.t(`tasks.tasksPage.filters.all`),
      },
      {
        id: REVIEW_REQUIRED_OPTION.REVIEW_REQUIRED,
        name: root.$i18n.t(`tasks.tasksPage.filters.reviewRequired.${REVIEW_REQUIRED_OPTION.REVIEW_REQUIRED}`),
      },
      {
        id: REVIEW_REQUIRED_OPTION.REVIEW_NOT_REQUIRED,
        name: root.$i18n.t(`tasks.tasksPage.filters.reviewRequired.${REVIEW_REQUIRED_OPTION.REVIEW_NOT_REQUIRED}`),
      },
    ]);

    const { result: firstFetchSelectedBusinesses } = useBusinessByNames({
      businessIds: computed(() => businessesParam.value || []),
    });

    const { currentTenant } = useTenancy();

    const { businesses, clearBusinesses } = useAccumulativeBusinesses(
      computed(() => ({
        ...(searchFilter.value.debounced !== '' && { search: searchFilter.value.debounced }),
      })),
      BUSINESSES_LIMIT,
      after
    );

    watch(
      route,
      () => {
        if (isFirstFetchReviewOption.value && route.query.reviewOption) {
          const reviewOption = route.query.reviewOption;
          selectedReviewOption.value = reviewOption;
          isFirstFetchReviewOption.value = false;
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      selectedReviewOption,
      debounce(
        () => {
          const query = {
            ...route.query,
          };

          if (selectedReviewOption?.value === REVIEW_REQUIRED_OPTION.ALL) {
            delete query.reviewOption;
          } else {
            query.reviewOption = selectedReviewOption.value;
          }

          router.push({ query });
        },
        { immediate: true, deep: true }
      )
    );

    const tasksType = computed(() =>
      Object.keys(TASK_TYPE).map((key) => ({
        id: TASK_TYPE[key],
        name: root.$i18n.t(`tasks.tasksPage.tasksName.${TASK_TYPE[key]}`),
        isPermitted: true,
      }))
    );

    const isReviewRequired = computed(() => {
      switch (selectedReviewOption.value) {
        case REVIEW_REQUIRED_OPTION.REVIEW_REQUIRED:
          return true;
        case REVIEW_REQUIRED_OPTION.REVIEW_NOT_REQUIRED:
          return false;
        default:
          return undefined;
      }
    });

    const selectedBusinessIds = computed(() => [...new Set(selectedBusinesses.value.map((business) => business.id))]);

    watch(
      [businessesParam, firstFetchSelectedBusinesses],
      () => {
        if (
          isFirstFetchBusinesses.value &&
          businessesParam.value?.length > 0 &&
          businessesParam.value?.length === firstFetchSelectedBusinesses?.value.length
        ) {
          selectedBusinesses.value = firstFetchSelectedBusinesses.value;
          isFirstFetchBusinesses.value = false;
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      selectedBusinessIds,
      debounce(
        () => {
          const query = {
            ...route.query,
          };

          if (selectedBusinessIds?.value?.length === 0) {
            delete query.businessIds;
          } else {
            query.businessIds = selectedBusinessIds.value.join(',');
          }

          router.push({ query });
        },
        { immediate: true, deep: true }
      )
    );

    const selectedTypeAndDomain = computed(() => {
      if (!selectedTasksTypes.value.length) {
        // set default:
        const [type, domain] = TASK_TYPE.DEFINE_PRODUCT.split('_');
        return { types: [type], domains: [domain] };
      }

      const domains = [];
      const types = [];

      for (const item of selectedTasksTypes.value) {
        const [type, domain] = item.id.split('_');

        if (domain) domains.push(domain);
        if (type) types.push(type);
      }

      return { types, domains };
    });

    watch(
      selectedTypeAndDomain,
      debounce(
        () => {
          const query = {
            ...route.query,
          };

          if (!selectedTasksTypes.value?.length) {
            delete query.tasks;
          } else {
            query.tasks = selectedTasksTypes.value.map((item) => item.id).join(',');
          }

          router.push({ query });
        },
        { immediate: true, deep: true }
      )
    );

    const tasksFilters = computed(() => ({
      businessIds: selectedBusinessIds.value,
      completed: false,
      domains: selectedTypeAndDomain.value.domains,
      types: selectedTypeAndDomain.value.types,
      reviewRequired: isReviewRequired.value,
      limit: PAGE_LIMIT,
      offset: (currentPage.value - 1) * PAGE_LIMIT,
    }));

    const {
      tasks,
      totalCount: tasksTotalCount,
      loading: tasksLoading,
      refetch: refetchTasks,
    } = useAllTasks(tasksFilters);

    onMounted(() => {
      intervalId.value = setInterval(() => {
        refetchTasks();
      }, TASKS_REFETCH_INTERVAL_MS);
    });

    onUnmounted(() => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
    });

    const updateBusinessesWithSelected = debounce(() => {
      businessesWithSelected.value = uniqBy(
        [...firstFetchSelectedBusinesses.value, ...selectedBusinesses.value, ...businesses.value],
        'id'
      );
    }, 50);

    watch([searchFilter, businesses, selectedBusinesses], updateBusinessesWithSelected, {
      immediate: true,
      deep: true,
    });

    const handleBusinessSelect = (selectedItems) => {
      selectedBusinesses.value = selectedItems;
    };

    const handleBusinessSearchChange = debounce((newSearchText) => {
      clearBusinesses();
      searchFilter.value.value = newSearchText || '';
      searchFilter.value.debounced = newSearchText || '';
      after.value = 0;
    }, 300);

    const handleLoadMoreBusiness = () => {
      after.value += 1;
    };

    const handleReviewSelect = (selectedItem) => {
      selectedReviewOption.value = selectedItem;
    };

    const handleTasksTypeSelect = (selectedItem) => {
      selectedTasksTypes.value = selectedItem;
    };

    const showPlayMode = () => {
      isPlayMode.value = true;
    };

    const hidePlayMode = () => {
      isPlayMode.value = false;
      selectedRowTaskId.value = null;
    };

    watch(
      route,
      () => {
        selectedRowTaskId.value = route.query.task;
      },
      { immediate: true, deep: true }
    );

    watch(tasks, () => {
      if (!tasks.value.length) {
        isPlayMode.value = false;
      }
    });

    const activeTasks = computed(() => tasks?.value?.filter((task) => task.activeAt <= Date.now()) || []);

    const handleRowClick = (rowId) => {
      selectedRowTaskId.value = rowId;
    };

    const onTaskComplete = refetchTasks;
    const onTaskSkip = refetchTasks;

    return {
      initialTasksIds: tasksFromQueryParams.map((t) => t.id),
      PAGE_LIMIT,
      currentPage,
      tasks: computed(() => tasks.value || []),
      tasksLoading,
      tasksTotalCount,
      currentTenant,
      selectedBusinesses,
      handleBusinessSelect,
      handleBusinessSearchChange,
      handleLoadMoreBusiness,
      businesses: businessesWithSelected,
      reviewOptions,
      isReviewRequired,
      handleReviewSelect,
      selectedReviewOption,
      handleTasksTypeSelect,
      tasksType,
      selectedTasksTypes,
      REVIEW_REQUIRED_OPTION,
      fakeLoading,
      showPlayMode,
      isPlayMode,
      selectedRowTaskId,
      tasksFilters,
      handleRowClick,
      onTaskComplete,
      onTaskSkip,
      hidePlayMode,
      activeTasks,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .filter-is-dirty {
    .filter-name {
      color: #1f284d;
    }

    .filter {
      border: 1px solid #1f284d;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dropdown-selected-item {
  color: #3661dd;
  background-color: #f5f7fa;
}

.container {
  min-width: 1100px;
  max-width: unset;
}

.filters {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
}

.tasks-table {
  max-width: 1272px;
}

.title {
  display: flex;
  max-width: 1320px;
}
</style>
